import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";

// Context
import { PageType } from "../components/context/page-type";
import { useColorContext } from "../components/context/color-context";

const PageContainer = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  & .full-width-text {
    & .text-container {
      grid-column: 1 / 7;

      & p {
        font-size: 40px;
        line-height: 52px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }

  & .text {
    & .text-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }

      & .inner-text-container {
        max-width: 895px;
        margin: 0 auto;

        & .section-title {
          & h1 {
            font-size: 40px;
            line-height: 52px;

            @media (max-width: 768px) {
              font-size: 26px;
              line-height: 34px;
            }
          }
        }

        & .section-text {
          & ol {
            margin: 1em 0;

            & li {
              list-style: decimal;
              margin: 0 0 1em 20px;
              padding: 0 0 0 10px;

              &:last-of-type {
                margin: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
  }

  & .gallery {
    grid-row-gap: 55px;

    & .single-image-container {
      grid-column: span 2;

      & .image-container {
        margin: 20px 0;
      }

      & .subtitle {
        & p {
          margin: 0;
        }
      }

      & .text {
        & p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      & img {
        margin: 0 auto;
        &.landscape {
          max-width: 60%;
        }

        &.portrait {
          // max-width: 195px;
          max-width: 45%;
        }
      }
    }
  }

  & .support {
    & .section-title {
      grid-column: 1 / 7;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;

  margin: 70px 0;

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const Page = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`page`);
  }, [data]);

  const content = data.prismicPage.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <Grid key={`text_${index}_${content.id}`} className="text">
          <div className="text-container">
            <div className="inner-text-container">
              <div
                className="section-title"
                dangerouslySetInnerHTML={{
                  __html: content.primary.section_title.html,
                }}
              />
              <div
                className="section-text"
                dangerouslySetInnerHTML={{
                  __html: content.primary.section_text.html,
                }}
              />
            </div>
          </div>
        </Grid>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicPage.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicPage.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicPage.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicPage.data.title.text} | Three Rivers | Bexley`,
          },
        ]}
      />
      <PageContainer>
        <ContentContainer>{content}</ContentContainer>
      </PageContainer>
    </>
  );
};

export default withPreview(Page);

export const query = graphql`
  query Page($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      data {
        title {
          text
        }
        body {
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              section_title {
                html
              }
              section_text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
